<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedCredentialActions"
        :default-value="properties.action"
        step-label="credential"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col v-if="['ENABLE', 'DISABLE', 'UPDATE'].includes(properties.action)" cols="12">
      <v-text-field
        :key="`${step.id}-queryName`"
        v-model="properties.fields.queryName"
        outlined
        dense
        class="required-asterisk"
        data-cy="credential-queryName"
        :label="$lang.labels.queryName"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
      />
    </v-col>

    <v-col v-if="['CREATE', 'UPDATE'].includes(properties.action)" cols="12">
      <v-text-field
        :key="`${step.id}-name`"
        v-model="properties.fields.name"
        outlined
        dense
        class="required-asterisk"
        data-cy="credential-name"
        :label="$lang.labels.name"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        @input="handleChange('fields.name', $event)"
      />
      <v-select
        :key="`${step.id}-type`"
        v-model="properties.fields.type"
        :items="formattedCredentialFieldsTypes"
        :label="$lang.labels.type"
        outlined
        dense
        required
        data-cy="credential-type"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        @change="handleChange('fields.type', $event)"
      />
      <roles-list
        :key="`${step.id}-roles`"
        :label="$lang.labels.roles"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk mb-4"
        data-cy="credential-roles"
        :can-edit="canEdit"
        :data="properties.fields.roles"
        @dataChanged="handleChange('fields.roles', $event)"
      />
    </v-col>

    <v-col v-if="properties.action === 'CREATE'" cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.fields.targetObject"
        outlined
        dense
        class="required-asterisk"
        data-cy="credential-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        @input="handleChange('fields.targetObject', $event)"
      />
    </v-col>

    <v-col v-if="['CREATE', 'UPDATE'].includes(properties.action)" cols="12" class="pt-1 pb-2">
      <add-key-value
        :key="`${step.id}-values`"
        step-type="CREDENTIAL"
        type="values"
        :can-edit="canEdit"
        :data="properties.fields.values"
        :single-step="step"
        :keep-numbers="true"
        @dataChanged="handleChange('fields.values', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  ActionSelect
} from './components'
import RolesList from '../RolesList.vue'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'
import {  castValueForStep, getNestedPropertyForSetting } from '@/utils/helpers'

import { CREDENTIAL_ACTIONS } from './constants'
// import { definitions } from '@/utils/definitions'

export default {
  name: 'CredentialStep',
  components: {
    ActionSelect,
    RolesList,
    AddKeyValue
  },
  mixins: [StepMixin],
  data() {
    return {
      credentialFieldsTypes: ['REST', 'PAYMENT_SENSE_PAC', 'PAYMENT_SENSE_CONNECT_E'] // will be definitions.CreateCredentialFields.properties.type.enum when we allow other types to be created
    }
  },
  computed: {
    formattedCredentialActions() {
      return CREDENTIAL_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedCredentialFieldsTypes() {
      return this.credentialFieldsTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.fields) this.properties.fields = {
      roles: []
    }
  },
  methods: {
    handleChange(key, val) {
      const updatedValue = castValueForStep(key, val, {
        shouldBeString: [
          'fields.queryName',
          'fields.name',
          'fields.values.hostUrl',
          'fields.values.username',
          'fields.values.apiKey',
          'fields.values.softwareHouseId',
          'fields.values.installerId',
          'fields.values.jwt',
          'fields.values.merchantUrl',
          'fields.targetObject'
        ],
        shouldBeMap: ['fields.values.values']
      })

      if (key.includes('.')) {
        const { nestedObj, lastKey } = getNestedPropertyForSetting(this.properties, key)

        this.$set(nestedObj, lastKey, updatedValue)
      } else {
        this.$set(this.properties, key, updatedValue)
      }

      this.$emit('update-step', { field: key, value: updatedValue })
    }
  }

}
</script>
